<template>
  <div class="main">
    <!-- 搜索栏 -->
    <ListToolBar @getList="getList" @onCreate="onCreate" />
    <!-- 运营管理 -->
    <custem-table :tableInfo="tableInfo" :tableLoading="tableLoading" @getList="getList"></custem-table>
    <!-- 表单 -->
    <form-data ref="formRef" @getList="getList"></form-data>
    <!-- 查看产品 -->
    <ShowProduct ref="show_product" />
    <!-- 企业详情 -->
    <ShowDetail ref="detai_win" />
    <!-- 确认企业 -->
    <SureEnter ref="sure_enter" @getList="getList"/>
  </div>
</template>

<script setup>
import ListToolBar from './components/ListToolBar'
import ShowProduct from './components/ShowPro'
import ShowDetail from './components/Detail.vue'
import FormData from './components/Form.vue'
import SureEnter from './components/SureEnterpris.vue'
import { enterpriseManagement } from '@/utils/api'
import { onMounted, reactive, ref, getCurrentInstance, nextTick, inject } from 'vue'

const formRef = ref() // 表单对象
const detai_win = ref()
const show_product = ref()
const sure_enter = ref()
const t = inject('t')
const { proxy } = getCurrentInstance()
const tableLoading = ref(false)

// 编辑
const editData = (row) => {
  const { id } = row
  if (!!!id) return
  enterpriseManagement.EnterpriseDetail(id).then(res => {
    if (!!!formRef.value) return
    formRef.value.openWin(res)
  })
}

// 创建
const onCreate = () => {
  if (!!!formRef.value) return
  formRef.value.openWin()
}
// 详情
const showDetail = (data) => {
  if (!!!detai_win.value) return
  detai_win.value.openWin(data)
}

// 查看产品
const showProduct = (data) => {
  if (!!!show_product.value) return
  show_product.value.openWin(data.id, data.name, data.sales_user_id)
}

const sureEnterprise = async (data) => {
  await nextTick()
  sure_enter.value.openWin(data)
}

// 表格信息
const tableInfo = reactive({
  header: [
    { // 名称
      key: 'name',
      sortable: true,
      label: t('enterprise.name'),
      minWidth: '320px',
    },
    { // 企业编码
      key: 'code',
      width: '320px',
      sortable: true,
      label: t('enterprise.code')
    },
    { // 所属销售
      key: 'sales',
      width: '320px',
      label: t('user.sales'),
      hidden: !!!proxy.$userRole(['SuperAmin', 'GeneralAdmin']),
      custem: (data) => {
        if (data.sales) return data.sales.name
        else return '-'
      }
    },
    { // 注册日期
      key: 'created_at',
      width: '180px',
      sortable: true,
      label: t('table.registration_time')
    },
    { // 审核状态
      key: 'status',
      width: '150px',
      sortable: true,
      label: t('enterprise.review_status'),
      custem: (data) => {
        return proxy.$Enterpriseformat(data.status, 'html')
      }
    },
    {
      label: t('table.action'),
      width: proxy.$userRole(['GeneralAdmin']) ? '300px' : '260px',
      fixed: 'right',
      handleFun: [
        {
          name: t('form.confirm'),
          fn: sureEnterprise,
          hidden: () => {
            return !!!proxy.$userRole(['GeneralAdmin'])
          },
          disabled: (data) => {
            return !!!(data.status === 0)
          }
        },
        {
          name: t('table.detail'),
          fn: showDetail
        },
        {
          name: t('table.modify'),
          disabled: (data) => {
            return data.status === 0
          },
          fn: editData
        },
        {
          name: t('enterprise.related_products'),
          fn: showProduct
        }
      ]
    }
  ]
})
// 获取企业列表
const getList = async (search = {}) => {
  tableLoading.value = true
  try {
    const res = await enterpriseManagement.EnterpriseList({
      ...search
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

onMounted(getList)
</script>